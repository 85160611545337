.team-page {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  background: white;
  display: flex;
}

.team-photo {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 5vw;
  padding-top: 16vh;
  padding-bottom: 10vw;
  padding-left: 10vw;

}

.team-photo img {
  width: 60vw;
  height: 40vw;
  margin-top: 2vw;
  border-radius: 15px;
  border: 3px solid black;
  padding: 5px;
  border-radius: 15px;
}

.team-names {
  display: flex;
  flex-direction: column;
  margin-top: 15vw; 
  padding-top: 5vh;
  padding-left: 5vw;
  font-size: 2.5vw;
  line-height: 300%;
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .team-page {
    flex-direction: column;
  }

  .team-photo {
    font-size: 7vh;
    padding-top: 20vw;
    padding-bottom: 0vw;
  }

  .team-photo img {
    width: 75vw;
    height: 50vw;
    padding: 5px;
    border-radius: 15px;
    border: 3px solid black;
  }

  .team-names {
    padding-top: 0vw;
    font-size: 5vw;
    line-height: 250%;
    text-align: center;
    padding-bottom: 10vw;
    padding-left: 0;
  }
}