.bot-info {
  background: #F0F0F0;
  color: black;
  padding: 5vh;
  font-family: 'Saira';
  display: flex;
  flex-wrap: wrap;
}

.bot-words {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.bot-about {
  width: 30%;
}

a:link {
  color: black;
}

a:visited {
  color: black;
}

a:hover {
  color: #B31B1B;
}

.bot-contact {
  text-align: center;
}

.bot-socials {
  justify-content: space-evenly;
  width: 100px;
  display: flex;
  margin: auto;
  text-align: center;
  gap: 10px;
}

.bot-contact img {
  height: 50px;
}

.bot-logo {
  background-color: #F0F0F0;
  padding: 2vw;
  text-align: center;
}

.bot-logo img {
  height: 8vw;
}


@media screen and (max-width: 900px) {
  .bot-words {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .bot-about {
    text-align: center;
    width: 100%;
    padding-left: 15vw;
    padding-right: 15vw;
    padding-bottom: 2vh;
  }

  .bot-links {
    text-align: center;
    padding: 2vh;
  }

  .bot-contact {
    padding: 2vh;
    flex-wrap:wrap-reverse;
  }

  .bot-logo img {
    height: 90px;
  }

  .bot-col img {
    padding: 0vw;
  }

}

@media screen and (max-width: 600px) {
  .bot-about {
    padding: 2vh;
  }

  .bot-contact img {
    height: 35px;
  }

  .bot-logo img {
    height: 75px;
  }

}

