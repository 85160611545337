.sponsors-background {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  background: white;
}

.sponsors-intro {
  text-align: center;
  font-size: 4.5vw;
  padding-top: 18vh;
}

.sponsors-inquries a {
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
    .sponsors-intro {
        font-size: 5.5vw;
    }
}
@media screen and (max-width: 950px) {
  .sponsors-intro {
    font-size: 7vh;
  }
}

.sponsors-message {
  padding: 1.5vw 14vw 0 14vw;
  text-align: center;
  font-size: 1.4vw;
  line-height: 2.5vw;
}

.sponsors-message a {
  color: #B31B1B;
  font-size: 1.6vw;
  line-height: 3vw;
}
@media screen and (max-width: 850px) {
  .sponsors-message {
    font-size: 2.2vh;
    line-height: 3.4vh;
  }
  .sponsors-message a {
    font-size: 2.7vh;
    line-height: 8vh;
  }
}

#logoBackground {
  padding-top: 3vw;
  padding-bottom: 15vh;
}
.sponsors-row {
  display: flex;
  align-items: center;
}
.sponsors-column {
  flex: 33.33%;
  padding: 3% 5% 3% 5%;
}

.comp_restrict {
  vertical-align: middle;
  text-align: center;
}
.comp_restrict img {
    max-width: 16vw;
    max-height: 15vh;
}
.comp {
  vertical-align: middle;
  text-align: center;
}
.comp img {
    max-width: 18vw;
    max-height: 18vh;
}
@media screen and (max-width: 850px) {
    .comp img {
        max-width: 23vw;
        max-height: 18vh;
    }
    .comp_restrict img {
        max-width: 21vw;
        max-height: 16vh;
    }
}

@media screen and (max-width: 850px) {
  #logoBackground {
    padding-bottom: 10vh;
  }
  .sponsors-column {
    padding: 5% 3% 5% 3%;
  }
}