

.pic{
    background-image: url('img/homepage/backs.JPG');
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    /* opacity: 0.75; */
    filter:brightness(0.5);
}

.home-title{
    /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    text-align: center;
    font-weight: 600;
    position: absolute;
    width: 100%;
    top: 35%;
    font-size: 5vw;
    color: #F5EFE7;
}

.home-description{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  font-weight: 300;
  position: absolute;
  width: 100%;
  top: 50%;
  font-size: 1.3vw;
  color: #F5EFE7;
  font-style:italic;
}

.container {
    height: 800px;
    position: relative;
    margin-top: 2vw;
    /* border: 3px solid green; */
  }
  
  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
  }

  .button {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    cursor: pointer;
    border: 3px solid;
    padding: 0.25em 0.5em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }
  
  @media (min-width: 768px) {
    .button {
      padding: 0.25em 0.75em;
    }
  }