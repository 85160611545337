.background {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  background: white;
}

#thanks {
  text-align: center;
  padding: 18vh 8vw 1vw 8vw;
  /* font-size: 3vw; */
  font-size: 4.5vw;
  margin-top: 3vw;
}

#thanks img {
  width: 75vw;
}

#info {
  padding: 1vw 22vw 0.5vw 22vw;
  font-size: 1.8vw;
  line-height: 2.5vw;
  text-align: center;
}

#info a {
  color: #B31B1B;
  ;
}

#info img {
  width: 50vw;
  padding: 6px;
  margin-top: 3vw;
  border-radius: 15px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.85);
  border-top: 3px solid rgba(0, 0, 0, 0.85);
  border-left: 3px solid rgba(0, 0, 0, 0.85);
  border-right: 3px solid rgba(0, 0, 0, 0.85);
}

.coffeeChats {
  font-size: 2.5vw;
  margin-left: 6.5vw;
  margin-bottom: -8vw;
  margin-top: 2vw;
}

.recruitment-row {
  width: auto;
  margin: 5vw;
}

.recruitment-column {
  float: left;
  width: 50%;
  padding: 20px;
  margin-top: 4vh;
  font-size: 1.5vw;
}

.recruitment-column-right {
  float: right;
  text-align: left;
  width: 40%;
  padding: 20px;
  margin-top: 4vh;
  font-size: 1.5vw;
}

.recruitment-row::after {
  content: "";
  clear: both;
  display: table;
  font-size: 1.5vw;
}

@media screen and (max-width: 950px) {
  #info {
    padding: 0 10vw 1.5vw 10vw;
    font-size: 2.4vh;
    line-height: 3.3vh;
  }

  #thanks img {
    width: 80vw;
  }
}

#infosessions{
  text-align: center;
}

#infoblurb{
  font-size: 4vh;
  font-weight:bold;
}

#info_session_title{
  font-size: 3vh;
  font-style: italic;
}

#timeline{
  font-size: 3vh;
  font-style: italic;
  margin-top: 20px;
}

#apps {
  text-align: center;
  margin-top: 20px;
}

#page1 {
  opacity: 1;
  padding-top: 5vw;
  padding-bottom: 10vh;
  text-align: center;
}

#page2 {
  display: none;
  padding-top: 5vw;
  padding-bottom: 10vh;
  text-align: center;
}

#promo-vid1 {
  opacity: 1;
  padding-top: 2vw;
  padding-bottom: 3vw;
  text-align: center;
}

#promo-vid2 {
  display: none;
  padding-top: 2vw;
  padding-bottom: 3vw;
  text-align: center;
}

#zoom-vid1 {
  opacity: 1;
  padding-bottom: 3vw;
  text-align: center;
}

#zoom-vid2 {
  display: none;
  padding-bottom: 3vw;
  text-align: center;
}

@media screen and (max-width: 850px) {
  #thanks {
    font-size: 5.5vh;
    padding: 20vh 8vw 3vw 8vw;
  }

  #info {
    padding-top: 1vh;
    font-size: 2.7vh;
  }

  #page1 {
    padding-top: 5vh;
  }
}

@media screen and (max-width: 650px) {
  #page1 {
    display: none;
  }

  #page2 {
    display: block;
    padding-top: 5vh;
    padding-bottom: 15vh;
    text-align: center;
  }
}

@media screen and (max-width: 650px) {
  #promo-vid1 {
    display: none;
  }

  #promo-vid2 {
    display: block;
    padding-top: 2vh;
    padding-bottom: 5vh;
    text-align: center;
  }

  #zoom-vid1 {
    display: none;
  }

  #zoom-vid2 {
    display: block;
    padding-bottom: 5vh;
    text-align: center;
  }
}

iframe {
  border: none;
  overflow: hidden;
}