/* .software-background {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  background: white;
}

.software-intro {
  text-align: center;
  font-family: 'Saira'; 
  font-size: 4.5vw;
  font-weight: 400;
  padding-top: 18vh;
}
.underline {
  font-family: 'Saira';
  text-align: center;
  padding-bottom: 6vh;
  line-height: 0.1vh;
}
.software-projects{
  max-width: 70rem;
  margin: 4rem auto;
  padding: 0 2rem;
  column-width: 30rem;
  column-gap: 1rem;
  display: block;
}

*, ::after, ::before{
  box-sizing: border-box;
}

.software-side-project{
  margin-bottom: 1rem;
  padding: 2rem;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  break-inside: avoid;
  display: block
}
@media screen and (max-width: 1200px) {
    .software-intro {
        font-size: 5.5vw;
    }
}
@media screen and (max-width: 950px) {
  .software-intro {
    font-size: 7vh;
  }
  .underline {
    padding-bottom: 4vh;
  }
}

#space {
  padding-top: 2vw;
}

.subteam-underline {
  font-family: 'Saira';
  text-align: center;
  padding-bottom: 6vh;
  line-height: 0.1vh;
}
@media screen and (max-width: 950px) {
  .subteam-underline {
    font-size: 4vw;
  }
}
.software-intro img {
  width: 50vw;
  padding: 6px;
  border-radius: 15px;
  border-bottom: 3px solid rgba(0,0,0,0.85);
  border-top: 3px solid rgba(0,0,0,0.85);
  border-left: 3px solid rgba(0,0,0,0.85);
  border-right: 3px solid rgba(0,0,0,0.85);
}
.software-description {
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 10vw;
  padding-right: 5vw;
  width: 45vw;
}
.software-overview {
  font-size: 1.6vw;
  padding: 6vw 10vw 1vw 10vw;
  line-height: 2.5vw;
}
.software-minor {
  font-size: 1.2vw;
  padding: 1vw 0;
  line-height: 2vw;
}
.software-bold {
  font-size: 1.2vw;
}

.software-subteam-cols {
  display: flex;
  flex-direction: row;
}
.software-pic {
  padding-top: 2.5vw;
  width: 30vw;
}
@media screen and (max-width: 950px) {
  .software-subteam-cols {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0;
  }
  .software-pic {
    width: 60vw;
  }
  .software-overview {
    font-size: 2.5vw;
    padding: 2vw 10vw;
    text-align: center;
    line-height: normal;
  }
  .software-description {
    padding: 5vw 1vw;
    text-align: left;
    width: 85vw;
  }

  .software-minor {
    font-size: 2.5vw;
    line-height: normal;
  }
  .software-bold {
    font-size: 2.5vw;
  }
  .details {
    padding-top: 4vw;
    padding-left: 5vw;
    padding-right: 5vw;
    text-align: left;
  }
  .software-pic {
    padding-bottom: 3vw;
  }
}
.software-pic img{
  width: 100%;
  padding: 6px;
  border-radius: 2px;
  border-bottom: 3px solid rgba(255, 255, 255, 0.85);
  border-top: 3px solid rgba(255, 255, 255, 0.85);
  border-left: 3px solid rgba(255, 255, 255, 0.85);
  border-right: 3px solid rgba(255, 255, 255, 0.85);
}

.software-subteam {
  padding: 2vw 5vw 10vw 5vw;
  display: flex;
  flex-direction: column;
}
.software-leads {
  padding-top: 10vw;
  padding-bottom: 5vw;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
}

.software-lead {
  text-align: center;
  position: relative;
}

.software-lead img {
  width: 20vw;
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
.software-lead .software-about {
  text-align: center;
  max-width: 20vw;
}
.software-members {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}
.software-subteam img {
  width: 20vw;
  padding-left: 1vw;
  padding-right: 1vw;
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
.software-member {
  padding-bottom: 2vw;
  text-align: center;
  position: relative;
}

.software-member .software-about {
  text-align: center;
  max-width: 20vw;
}
.software-lead .software-name {
  font-weight: bold;
  font-size: 1.7vw;
}
.software-title {
  font-size: 1.3vw;
}
.software-members .software-name {
  font-weight: bold;
  font-size: 1.7vw;
}
.software-info {
  font-size: 1vw;
  line-height: 2vw;
  max-width: inherit;
}

@media screen and (min-width: 1300px) {
  .software-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .software-lead:hover .image {
    opacity: 0.35;
  }
  
  .software-lead:hover .software-middle {
    opacity: 1;
  }
  
  .software-member:hover .image {
    opacity: 0.35;
  }
  
  .software-member:hover .software-middle {
    opacity: 1;
  }
  
  .software-funfact {
    color: black;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
  }
}

@media screen and (max-width: 1299px) {
  .software-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .software-lead:hover .image {
    opacity: 0.35;
  }
  
  .software-lead:hover .software-middle {
    opacity: 1;
  }
  
  .software-member:hover .image {
    opacity: 0.35;
  }
  
  .software-member:hover .software-middle {
    opacity: 1;
  }
  
  .software-funfact {
    color: black;
    font-size: 10px;
    font-weight: bold;
  }
}

@media screen and (max-width: 450px) {
  .software-funfact {
      font-size: 1.5vw;
  }
  
  .software-intro img {
    border: 1.5px solid black;
    width:75%;
    height: 50%;
  }
  .software-overview{
    font-size: 2.2vh;
  }
} */

.software-background {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  background: white;
}

.software-intro {
  text-align: center;
  font-family: 'Saira'; /*Orbitron*/
  font-size: 4.5vw;
  font-weight: 400;
  padding-top: 18vh;
}
.underline {
  font-family: 'Saira';
  text-align: center;
  padding-bottom: 6vh;
  line-height: 0.1vh;
}

.software-projects{
  max-width: 70rem;
  margin: 4rem auto;
  padding: 1rem 2rem;
  column-width: 30rem;
  column-gap: 1rem;
  display: block;
}

*, ::after, ::before{
  box-sizing: border-box;
}

.software-side-project{
  margin-bottom: 1rem;
  padding: 2rem 2rem;
  background-color: white;
  /* box-shadow: 0 0 4px 0 #0000004d; */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  break-inside: avoid;
  display: block;
}

@media screen and (max-width: 1200px) {
    .software-intro {
        font-size: 5.5vw;
    }
}
@media screen and (max-width: 950px) {
  .software-intro {
    font-size: 7vh;
  }
  .underline {
    padding-bottom: 4vh;
  }
}

#space {
  padding-top: 2vw;
}

.subteam-underline {
  font-family: 'Saira';
  text-align: center;
  padding-bottom: 6vh;
  line-height: 0.1vh;
}
@media screen and (max-width: 950px) {
  .subteam-underline {
    font-size: 4vw;
  }
}
.software-intro img {
  width: 50vw;
  padding: 6px;
  border-radius: 15px;
  border-bottom: 3px solid black;
  border-top: 3px solid black;
  border-left: 3px solid black;
  border-right: 3px solid black;
}
.software-description {
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 10vw;
  padding-right: 5vw;
  width: 45vw;
}
.software-overview {
  font-size: 1.6vw;
  padding: 6vw 10vw 1vw 10vw;
  line-height: 2.5vw;
}
.software-minor {
  font-size: 1.2vw;
  padding: 1vw 0;
  line-height: 2vw;
}
.software-bold {
  font-size: 1.2vw;
}

.software-subteam-cols {
  display: flex;
  flex-direction: row;
  /* padding-right: 10vw; */
}
.software-pic {
  padding-top: 2.5vw;
  width: 30vw;
}
@media screen and (max-width: 950px) {
  .software-subteam-cols {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0;
  }
  .software-pic {
    width: 60vw;
  }
  .software-overview {
    font-size: 2.5vw;
    padding: 2vw 10vw;
    text-align: center;
    line-height: normal;
  }
  .software-description {
    padding: 5vw 1vw;
    text-align: left;
    width: 85vw;
  }

  .software-minor {
    font-size: 2.5vw;
    line-height: normal;
  }
  .software-bold {
    font-size: 2.5vw;
  }
  .details {
    padding-top: 4vw;
    padding-left: 5vw;
    padding-right: 5vw;
    text-align: left;
  }
  .software-pic {
    padding-bottom: 3vw;
  }
}
.software-pic img{
  width: 100%;
  padding: 6px;
  border-radius: 2px;
  border-bottom: 3px solid white;
  border-top: 3px solid white;
  border-left: 3px solid white;
  border-right: 3px solid white;
}

.software-subteam {
  padding: 2vw 5vw 10vw 5vw;
  display: flex;
  flex-direction: column;
}
.software-leads {
  padding-top: 1vw;
  padding-bottom: 5vw;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
}

.software-lead {
  /* padding-bottom: 2vw; */
  text-align: center;
  position: relative;
}

.software-lead img {
  width: 20vw;
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
.software-lead .software-about {
  text-align: center;
  max-width: 20vw;
}
.software-members {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}
.software-subteam img {
  width: 20vw;
  padding-left: 1vw;
  padding-right: 1vw;
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
.software-member {
  padding-bottom: 2vw;
  text-align: center;
  position: relative;
}

.software-member .software-about {
  text-align: center;
  max-width: 20vw;
}
.software-lead .software-name {
  font-weight: bold;
  font-size: 1.7vw;
}
.software-title {
  font-size: 1.3vw;
  /* padding-bottom: 0.8vw; */
}
.software-members .software-name {
  font-weight: bold;
  font-size: 1.7vw;
  /* padding: 1vw 0; */
}
.software-info {
  font-size: 1vw;
  line-height: 2vw;
  max-width: inherit;
}

@media screen and (min-width: 1300px) {
  .software-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .software-lead:hover .image {
    opacity: 0.35;
  }
  
  .software-lead:hover .software-middle {
    opacity: 1;
  }
  
  .software-member:hover .image {
    opacity: 0.35;
  }
  
  .software-member:hover .software-middle {
    opacity: 1;
  }
  
  .software-funfact {
    color: black;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
  }
}

@media screen and (max-width: 1299px) {
  .software-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .software-lead:hover .image {
    opacity: 0.35;
  }
  
  .software-lead:hover .software-middle {
    opacity: 1;
  }
  
  .software-member:hover .image {
    opacity: 0.35;
  }
  
  .software-member:hover .software-middle {
    opacity: 1;
  }
  
  .software-funfact {
    color: black;
    font-size: 10px;
    font-weight: bold;
  }
}

@media screen and (max-width: 480px) {
  .software-funfact {
      font-size: 1.5vw;
  }
  .software-intro img {
    border: 1.5px solid black;
    width:75%;
    height: 50%;
  }
  .software-overview{
    font-size: 2.2vh;
  }

}



