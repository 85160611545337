* {
  box-sizing: border-box;
}

.vehicles-background {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  background: white;
}

.vehicles-intro {
  text-align: center;
  font-size: 4.5vw;
  padding-top: 18vh;
}
/* CURRENT SECTION_________________________________ */
.vehicles-current-name {
  text-align: center;
  font-size: 2.5vw;
  font-weight: 600;
}
.vehicles-current{
  text-align: left;
  font-size: 1.5vw;
  padding: 5vh;
  margin-left: 10vh;
  margin-right: 10vh;
}

.vehicles-current img{
  width: 100%;
  border: 2px solid #fff;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;
  -moz-border-radius:25px;
  -webkit-border-radius:25px;
  border-radius:25px;
}

.vehicles-column {
  float: left;
  width: 50%;
  padding: 20px;
  margin-top: 4vh;
}

.vehicles-row::after{
  content:"";
  clear: both;
  display: table;
}

/* TIMELINE SECTION_________________________________ */

.vehicles-timeline-name {
  text-align: center;
  font-size: 2.5vw;
  font-weight: 600;
}

/* the vertical ruler */
.vehicles-timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;
  margin-top: 20px;
}

/* the vertical ruler */
.vehicles-timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background: black;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
  margin-bottom: 10px;
}

.vehicles-container {
  padding: 15px 30px;
  position: relative;
  background: inherit;
  width: 50%;
}

.vehicles-container.left {
  left: 0;
  text-align: right;
}

.vehicles-container.right {
  left: 50%
}

/*circles on timeline*/
.vehicles-container::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  background: #ffffff;
  border: 2px solid black;
  border-radius: 16px;
  z-index: 1;
}

.vehicles-container.right::after {
  left: -8px;
}

.vehicles-container .vehicles-date {
  font-weight: bold;
}


.vehicles-container.right .vehicles-content img{
  margin-top: 8px;
  width: 70%;
  margin-right: auto;

  border: 2px solid #fff;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;
  -moz-border-radius:25px;
  -webkit-border-radius:25px;
  border-radius:25px;
}

.vehicles-container.left .vehicles-content img {
  margin-top: 8px;
  width: 70%;
  margin-left: auto;
  text-align: right;

  border: 2px solid #fff;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;
  -moz-border-radius:25px;
  -webkit-border-radius:25px;
  border-radius:25px;
}



.vehicles-category {
  float: left;
  margin-top: 10px;
  font-family: 'Saira',"Audiowide", sans-serif;
  line-height: 3vw;
  margin-left: 20px;
  font-weight: normal;
}

.vehicles-des {
  text-align: right;
  margin-top: 10px;
  line-height: 3vw;
  font-weight: 200;
  margin-right: 2vw;

}

@media screen and (max-width: 1200px) {
  /* laptop view */
  .vehicles-intro {
      font-size: 5.5vw;
  }
}
@media screen and (max-width: 950px) {
  .vehicles-intro {
    font-size: 7vh;
  }
}
  
@media screen and (max-width: 850px) {
  .vehicles-category {
    line-height: 5vw;
    font-size: 1.8vh;
  }
  .vehicles-des{
    line-height: 5vw;
    font-size: 1.8vh;
  } 
  .vehicles-current-name {
    font-size: 3.5vh;
  }
  .vehicles-timeline-name {
    font-size: 3.5vh;
  }

  h2 {
    font-size: 2.2vh;
  }

  .vehicles-column {
    font-size: 1.5vh;
    padding: 1vh;
  }

  .vehicles-current {
    margin-left: 1vw;
    margin-right: 1vw;
  }
  
}

@media screen and (max-width: 480px) {
  /* mobile devices */
  .vehicles-column{
    width: auto;
    text-align: center;
    font-size: 2.2vh;
  }

  .vehicles-timeline::after {
    left: 31px;
  }

  .vehicles-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  .vehicles-container.left::after, .vehicles-container.right::after {
    left: 23px;
  }

  .vehicles-container.right {
    left: 0%;
  }
  .vehicles-container.left {
    text-align: left;
  }
  .vehicles-content img {
    text-align: left;
  }
  .vehicles-category{
  margin-left: -5px;
  }

}

