.platform-background {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: black;
    background: white;
  }
  
  .platform-intro {
    text-align: center;
    font-family: 'Saira'; /*Orbitron*/
    font-size: 4.5vw;
    font-weight: 400;
    padding-top: 18vh;
  }

  .underline {
    font-family: 'Saira';
    text-align: center;
    padding-bottom: 6vh;
    line-height: 0.1vh;
  }
  
  .platform-projects{
    max-width: 70rem;
    margin: 4rem auto;
    padding: 0 2rem;
    column-width: 30rem;
    column-gap: 1rem;
    display: block;
  }
  
  *, ::after, ::before{
    box-sizing: border-box;
  }
  
  @media screen and (max-width: 1200px) {
      .platform-intro {
          font-size: 5.5vw;
      }
  }
  @media screen and (max-width: 950px) {
    .platform-intro {
      font-size: 7vh;
    }
    .underline {
      padding-bottom: 4vh;
    }
  }
  
  #space {
    padding-top: 2vw;
  }
  
  .subteam-underline {
    font-family: 'Saira';
    text-align: center;
    padding-bottom: 6vh;
    line-height: 0.1vh;
  }
  @media screen and (max-width: 950px) {
    .subteam-underline {
      font-size: 4vw;
    }
  }
  .platform-intro img {
    width: 50vw;
    padding: 6px;
    border-radius: 15px;
    border-bottom: 3px solid rgba(0,0,0,0.85);
    border-top: 3px solid rgba(0,0,0,0.85);
    border-left: 3px solid rgba(0,0,0,0.85);
    border-right: 3px solid rgba(0,0,0,0.85);
  }
  .platform-description {
    font-size: 1.0vw;
    margin: auto;
    width: 75%;
    padding: 10px;
    text-align: center;
    margin-bottom: 100px;
  }
  .platform-overview {
    font-size: 1.6vw;
    margin: auto;
    width: 75%;
    padding: 10px;
    text-align: center;
    margin-bottom: 100px
  }
  .platform-minor {
    font-size: 1.2vw;
    padding: 1vw 0;
    line-height: 2vw;
  }
  .platform-bold {
    font-size: 1.2vw;
  }
  
  .platform-subteam-cols {
    display: flex;
    flex-direction: row;
    /* padding-right: 10vw; */
  }
  .platform-pic {
    padding-top: 2.5vw;
    width: 30vw;
  }
  @media screen and (max-width: 950px) {
    .platform-subteam-cols {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 0;
    }
    .platform-pic {
      width: 60vw;
    }
    .platform-overview {
      font-size: 2.5vw;
      padding: 2vw 10vw;
      text-align: center;
      line-height: normal;
    }
    .platform-description {
      padding: 5vw 1vw;
      text-align: left;
      width: 85vw;
    }
  
    .platform-minor {
      font-size: 2.5vw;
      line-height: normal;
    }
    .platform-bold {
      font-size: 2.5vw;
    }
    .platform-details {
      padding-top: 4vw;
      padding-left: 5vw;
      padding-right: 5vw;
      text-align: left;
    }
    .platform-pic {
      padding-bottom: 3vw;
    }
  }
  .plaform-pic img{
    width: 100%;
    padding: 6px;
    border-radius: 2px;
    border-bottom: 3px solid rgba(255, 255, 255, 0.85);
    border-top: 3px solid rgba(255, 255, 255, 0.85);
    border-left: 3px solid rgba(255, 255, 255, 0.85);
    border-right: 3px solid rgba(255, 255, 255, 0.85);
  }
  
  .platform-subteam {
    padding: 2vw 5vw 10vw 5vw;
    display: flex;
    flex-direction: column;
  }
  .platform-leads {
    padding-top: 1vw;
    padding-bottom: 5vw;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
  }
  
  .platform-lead {
    /* padding-bottom: 2vw; */
    text-align: center;
    position: relative;
  }
  
  .platform-lead img {
    width: 20vw;
    opacity: 1;
    display: block;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  .platform-lead .platform-about {
    text-align: center;
    max-width: 20vw;
  }
  .platform-members {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .platform-subteam img {
    width: 20vw;
    padding-left: 1vw;
    padding-right: 1vw;
    opacity: 1;
    display: block;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  .platform-member {
    padding-bottom: 2vw;
    text-align: center;
    position: relative;
  }
  
  .platform-member .platform-about {
    text-align: center;
    max-width: 20vw;
  }
  
  .platform-lead .platform-name {
    font-weight: bold;
    font-size: 1.7vw;
    
  }
  .platform-title {
    font-size: 1.3vw;
    /* padding-bottom: 0.8vw; */
  }
  .platform-members .platform-name {
    font-weight: bold;
    font-size: 1.7vw;
    /* padding: 1vw 0; */
  }
  .platform-info {
    font-size: 1vw;
    line-height: 2vw;
    max-width: inherit;
    margin-bottom: 100px;
  }
  
  @media screen and (min-width: 1300px) {
    .platform-middle {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
    
    .platform-lead:hover .image {
      opacity: 0.35;
    }
    
    .platform-lead:hover .platform-middle {
      opacity: 1;
    }
    
    .platform-member:hover .image {
      opacity: 0.35;
    }
    
    .platform-member:hover .platform-middle {
      opacity: 1;
    }
    
    .plafomr-funfact {
      color: black;
      font-size: 20px;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-weight: bold;
    }
  }
  
  @media screen and (max-width: 1299px) {
    .platform-middle {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
    
    .platform-lead:hover .image {
      opacity: 0.35;
    }
    
    .platform-lead:hover .platform-middle {
      opacity: 1;
    }
    
    .platform-member:hover .image {
      opacity: 0.35;
    }
    
    .platform-member:hover .platform-middle {
      opacity: 1;
    }
    
    .platform-funfact {
      color: black;
      font-size: 10px;
      font-weight: bold;
    }
  }
  
  
  @media screen and (max-width: 450px) {
    .platform-funfact {
        font-size: 1.5vw;
    }
   
    .platform-intro img {
      border: 1.5px solid black;
      width:75%;
      height: 50%;
    }
    .platform-overview{
      font-size: 2.2vh;
    } 
  }
  