.leads-background {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  background: white;
}

.leads-intro {
  text-align: center;
  font-family: 'Saira'; /*Orbitron*/
  font-size: 4.5vw;
  font-weight: 400;
  padding-top: 18vh;
}
.underline {
  font-family: 'Saira';
  text-align: center;
  padding-bottom: 6vh;
  line-height: 0.1vh;
}

@media screen and (max-width: 1200px) {
    .leads-intro {
        font-size: 5.5vw;
    }
}
@media screen and (max-width: 950px) {
  .leads-intro {
    font-size: 7vh;
  }
  .underline {
    padding-bottom: 4vh;
  }
}

#space {
  padding-top: 2vw;
}

.leads-intro img {
  width: 50vw;
  padding: 6px;
  border-radius: 15px;
  border-bottom: 3px solid rgba(0,0,0,0.85);
  border-top: 3px solid rgba(0,0,0,0.85);
  border-left: 3px solid rgba(0,0,0,0.85);
  border-right: 3px solid rgba(0,0,0,0.85);
}

.leads-overview {
  font-size: 1.6vw;
  padding: 6vw 10vw 1vw 10vw;
  line-height: 2.5vw;
}

.leads-subleads-cols {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 950px) {
  .leads-subleads-cols {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0;
  }

  .leads-overview {
    font-size: 2.5vw;
    padding: 2vw 10vw;
    text-align: center;
    line-height: normal;
  }
}

.leads-subleads {
  padding: 2vw 5vw 10vw 5vw;
  display: flex;
  flex-direction: column;
}
.team-leads {
  padding-top: 1vw;
  padding-bottom: 5vw;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
}

.team-lead {
  text-align: center;
  position: relative;
}

.team-lead img {
  width: 20vw;
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
.team-lead .lead-about {
  text-align: center;
  max-width: 20vw;
}
.subteam-leads {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}
.leads-subleads img {
  width: 20vw;
  padding-left: 1vw;
  padding-right: 1vw;
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
.subteam-lead {
  padding-bottom: 2vw;
  text-align: center;
  position: relative;
}

.subteam-lead .lead-about {
  text-align: center;
  max-width: 20vw;
}
.team-lead .lead-name {
  font-weight: bold;
  font-size: 1.7vw;
}
.lead-title {
  font-size: 1.3vw;
}
.subteam-leads .lead-name {
  font-weight: bold;
  font-size: 1.7vw;
}
.lead-info {
  font-size: 1vw;
  line-height: 2vw;
  max-width: inherit;
}

@media screen and (min-width: 1300px) {
  .lead-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .team-lead:hover .image {
    opacity: 0.35;
  }
  
  .team-lead:hover .lead-middle {
    opacity: 1;
  }
  
  .subteam-lead:hover .image {
    opacity: 0.35;
  }
  
  .subteam-lead:hover .lead-middle {
    opacity: 1;
  }
  
  .lead-funfact {
    color: black;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
  }
}

@media screen and (max-width: 1299px) {
  .lead-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .team-lead:hover .image {
    opacity: 0.35;
  }
  
  .team-lead:hover .lead-middle {
    opacity: 1;
  }
  
  .subteam-lead:hover .image {
    opacity: 0.35;
  }
  
  .subteam-lead:hover .lead-middle {
    opacity: 1;
  }
  
  .lead-funfact {
    color: black;
    font-size: 10px;
    font-weight: bold;
  }
}


@media screen and (max-width: 450px) {
  .lead-funfact {
      font-size: 1.5vw;
  }
 
  .leads-intro img {
    border: 1.5px solid black;
    width:75%;
    height: 50%;
  }
  .leads-overview{
    font-size: 2.2vh;
  } 
}
