.about-background {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  background: white;
}

.about-intro {
  text-align: left;
  font-size: 4.5vw;
  padding-top: 18vh;
  font-family: 'Saira';
  padding-left: 10vw;
}

.about-blocks {
  font-size: 1.6vw;
  font-weight: 400;
  line-height: 4vh;
  float: left;
  width: 600px;
  padding-top: 5vh;
  padding-left: 10vw;
  text-align: left;
  font-family: 'Saira';
}

#block1 {
  margin-top: 30px;
  margin-bottom: 20px;
}

#block2 {
  margin-bottom: 20px;
}

#block3 {
  margin-bottom: 20px;
}

#shell {
  text-align: center;
  padding: 5vh 5vw;
}

#shell img {
  width: 40vw;
  height: 28vw;
  padding: 5px;
  border-radius: 15px;
  border: 3px solid black;
}

.awards {
  text-align: left;
  font-size: 3.5vw;
  padding-top: 5vh;
  padding-left: 10vw;
  font-family: 'Saira';
}

.awardNames {
  font-size: 1.5vw;
  font-weight: 400;
  padding-left: 15vw;
  padding-right: 15vw;
  font-family: 'Saira';
}

.about-col img {
  width: 4.5vw;
  height: 4vw;
}

.about-columns {
  display: flex;
  flex-direction: space-between;
  padding-bottom: 10vh;
  padding-top: 12vh;
}

.about-col {
  width: 33.333333vw;
  text-align: center;
  line-height: 3.3vh;
}

.under {
  padding-top: 0.5vw;
  padding-bottom: 2vw;
  color: #B31B1B;
  font-family: 'Saira';
}

.about-text {
  padding: 0 18%;
  font-size: 1.2vw;
  font-weight: 300;
}

.about-text a {
  color: #B31B1B;
}

@media screen and (max-width: 900px) {
  .about-intro {
    font-size: 7vh;
    text-align: left;
  }

  .about-blocks {
    inline-size: auto;
    overflow-wrap: break-word;
    padding-right: 10vw;
    padding-left: 10vw;
    font-size: 2.2vw;
    text-align: left;
    padding-top: 0;
    padding-bottom: 5vh;
  }

  #shell img {
    max-width: 100%;
    height: auto;
    padding: 3px;
    border-radius: 10px;
    border: 2px solid black;
  }

  .awards {
    font-size: 5.5vh;
  }

  .awardNames {
    font-size: 1.8vh;
  }

  .about-text {
    font-size: 1.8vh;
  }

  .underline {
    padding-bottom: 4vh;
  }

  .about-col img {
    width: 7.5vh;
    height: auto;
  }

  .about-columns {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vw 0;
  }

  .about-col {
    padding-bottom: 8vh;
    width: 50vw;
  }

  .about-text {
    padding: 0;
    font-size: 1.9vh;
  }

  .under {
    padding-top: 0vw;
    padding-bottom: 2.7vw;
  }
}

@media screen and (max-width: 450px) {
  .about-intro {
    font-size: 4.1vh;
    text-align: left;
    font-weight: bold;
  }

  .about-blocks {
    font-size: 2.2vh;
    /* inline-size: auto;
    overflow-wrap: break-word;
    padding-right: 10vw;
    padding-left: 10vw;
    font-size: 2.5vw;
    text-align: left;
    padding-top: 0; */
  }

  .shell {
    padding-top: 5vh;
  }

  #shell img {
    height: auto;
    width: auto;
    padding: 3px;
    border-radius: 10px;
    border: 2px solid black;
  }

  .awards {
    font-size: 5.5vh;
  }

  .awardNames {
    font-size: 2.2vh;
  }

  .award-des {
    font-size: 2vh;
  }

  .underline {
    padding-bottom: 4vh;
  }

  .about-col img {
    width: 7.5vh;
    height: auto;
  }

  .about-columns {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vw 0;
  }

  .about-col {
    padding-bottom: 8vh;
    width: 50vw;
  }

  .about-text {
    padding: 0;
    font-size: 1.9vh;
  }

  .under {
    padding-top: 0vw;
    padding-bottom: 2.7vw;
  }
}